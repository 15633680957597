import React from "react"
import { graphql } from "gatsby"
import { Layout } from "../layouts/Site"
import { slug } from "../gatsby/articles.create"
import { ArticlesPage, RecentArticles } from "../components/Blog"
import { HeaderPage, Section } from "../components/Containers"
import { PageHeading } from "../components/Headings"
import { withDefaultImage, withUrl } from "../components/Article"
import { Image } from "../components/Image"
import { sectionEndLine } from "../styles/styles"
import { Helmet } from "react-helmet";

export default function TagTemplate({ data, pageContext }) {
  const { site, blog, tag, mostRecentArticle, articles, defaultMetadata } = data
  const blogDefaults = blog.contents[0]
  if (pageContext.pageNumber === 0)
    articles.nodes = articles.nodes.slice(1)

  return <Layout title={`Musings about ${tag.description}`}>
    <Helmet>
      <meta name="description" content={blog.metaDescription || defaultMetadata.metaDescription} />
    </Helmet>
    <HeaderPage hideControl className={sectionEndLine}>
      <Section cols='auto' className='pt-4'>
        <div>
          <PageHeading>Musings about {tag.description}</PageHeading>
          <Image image={tag.image || blogDefaults.image} />
        </div>
        <RecentArticles>{enrich(mostRecentArticle.nodes)}</RecentArticles>
      </Section>
    </HeaderPage>
    <ArticlesPage pathPrefix={slug('tags', tag.name)} pageContext={pageContext}>{enrich(articles.nodes)}</ArticlesPage>
  </Layout>

  function enrich(articles) {
    return articles.map(withDefaultImage(blogDefaults.image)).map(withUrl(site.siteMetadata.url))
  }
}

export const query = graphql`query($tag: String!, $skip: Int!, $limit: Int!) {
  site: site {
    siteMetadata {
      url
    }
  }
  blog: contentfulSiteContentList(key: {eq: "blog"}) {
    key
    name
    contentful_id
    contents {
      ... on ContentfulSiteContent {
        ...Content        
      }
    }
  }
  tag: contentfulSiteArticleTag(name: {eq: $tag}) {
    name
    description
    metaDescription
    contentful_id
    image {
      ...ImageContent
    }
  }
  mostRecentArticle: allContentfulSiteArticle(
    sort: {order: DESC, fields: publishDate}
    limit: 1
    filter: {tags: {elemMatch: {name: {eq: $tag}}}}
  ) {
    nodes {
      ...ArticleDetails
    }
  }
  articles: allContentfulSiteArticle(
    sort: {order: DESC, fields: publishDate}
    skip: $skip
    limit: $limit
    filter: {tags: {elemMatch: {name: {eq: $tag}}}}
  ) {
    nodes {
      ...ArticleDetails
    }
  }
  defaultMetadata: contentfulSiteContentList(key: {eq: "index"}) {
    metaDescription
  } 
}`